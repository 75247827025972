/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { MEDIA_QUERIES } from "~utils/css";
import { Go } from "~components";

const Wrapper = styled.section`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 4rem 0 0 0;
`;

const Links = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: nowrap;

  @keyframes testimonial-marquee {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(calc(-100%), 0, 0);

      ${MEDIA_QUERIES?.desktop} {
        transform: translate3d(calc(-100% / 1.5), 0, 0);
      }
    }
  }

  animation: testimonial-marquee 10s linear infinite;

  ${MEDIA_QUERIES.hoverable} {
    &:hover {
      animation-play-state: paused;
    }
  }
`;

/** ============================================================================
 * @component
 * Component for rendering a scrolling marquee of links
 */
const ShareMarquee = ({
  className = ``,
  data: { backgroundColor, fontColor }
}) => {
  const { sanityGlobals } = useStaticQuery(graphql`
    query ShareMarquee {
      sanityGlobals {
        socialBackgroundColor {
          hex
        }

        socialFontColor {
          hex
        }

        socialLinks {
          text
          url
        }
      }
    }
  `);

  const links = sanityGlobals?.socialLinks || [];

  const background =
    backgroundColor?.hex || sanityGlobals.socialBackgroundColor.hex;
  const font = fontColor?.hex || sanityGlobals.socialFontColor.hex;

  return (
    <Wrapper className={className}>
      <div
        css={css`
          width: 100%;
          position: relative;
          background-color: ${background};
          color: ${font};
          padding: 0.75rem 0;
        `}
        className="b3"
      >
        <Links>
          {Array(3).fill(
            links.map((link) => (
              <Go
                to={link.url}
                _css={[
                  css`
                    width: calc(100vw / ${links?.length * 0.5});
                    flex: 1 0 auto;
                    text-align: center;

                    ${MEDIA_QUERIES?.desktop} {
                      width: calc(100vw / ${links?.length * 1.5});
                    }
                  `
                ]}
              >
                {link.text}
              </Go>
            ))
          )}
        </Links>
      </div>
    </Wrapper>
  );
};

export const query = graphql`
  fragment ShareMarqueeFragment on SanityShareMarquee {
    _type

    backgroundColor {
      hex
      title
    }

    fontColor {
      hex
      title
    }
  }
`;

export default ShareMarquee;
